import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useLocation } from "react-router-dom";
import Profile from "../../assets/img.png";
import {
  MdKeyboardArrowLeft,
  MdOutlineDateRange,
  MdOutlineMail,
} from "react-icons/md";
import { FaChevronDown, FaPhoneAlt } from "react-icons/fa";

function CareBookingDetails() {
  const { state } = useLocation();
  const booking = state?.booking;
  console.log(booking);

  const [isOpen, setIsOpen] = useState(false);
  const [status, setStatus] = useState(booking?.careStatus || "");
  const [statuses, setStatuses] = useState([]); // Initialize with an empty array

  // Function to fetch statuses from the API
  const fetchStatuses = async () => {
    const options = {
      method: "GET",
      url: `${process.env.REACT_APP_BASE_URL}:8899/booking-service/admin/status-for-edit`,
    };

    try {
      const { data } = await axios.request(options);
      setStatuses(data); // Set the fetched statuses
    } catch (error) {
      console.error("Error fetching statuses:", error);
    }
  };

  // Fetch the statuses when the component mounts
  useEffect(() => {
    fetchStatuses();
  }, []);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleStatusChange = async (newStatus) => {
    const previousStatus = status; // Store the previous status
    setStatus(newStatus);
    setIsOpen(false);

    const options = {
      method: "PUT",
      url: `${process.env.REACT_APP_BASE_URL}:8899/booking-service/admin/update-status-repair`,
      params: { bookingId: booking?.serviceUuid, status: newStatus },
    };

    try {
      const { data } = await axios.request(options);
      if (data.status === 200) {
        toast.success(data.msg || "Status updated successfully");
      } else {
        throw new Error("Failed to update status");
      }
    } catch (error) {
      console.error("Error updating status:", error);
      toast.error("Cannot update payment related status");
      setStatus(previousStatus); // Revert to previous status on error
    }
  };

  return (
    <div>
      <ToastContainer />
      <div className="sm:block hidden">
        <button
          onClick={() => window.history.back()}
          className="hidden whitespace-nowrap px-[10px] py-[5px] sm:flex items-center justify-center gap-2 bg-white border border-gray-300 rounded-md shadow-sm"
        >
          <span className="">
            <MdKeyboardArrowLeft />
          </span>
          <span className="font-bold font-plus-jakarta-sans text-[14px]">
            Go Back
          </span>
        </button>
        <div className="mt-4">
          <div className="border rounded-[24px] shadow py-4 px-6">
            <div className="flex gap-[14px]">
              {/* <img className="object-cover" src={Profile} alt="" /> */}
              <div>
                <h3 className="text-[#101828] text-[18px] font-semibold">
                  {booking?.address?.name || "N/A"}
                </h3>
                <p className="text-[#475467] text-sm">
                  ID: {booking?.serviceUuid || "N/A"}
                </p>
              </div>
            </div>
            <div className="mt-4 flex gap-[42px]">
              <div className="flex flex-col items-center justify-center">
                <div className="flex gap-[6px]">
                  <MdOutlineMail size={18} color="#E4B703" />
                  <h3 className="text-sm font-bold text-[#475467]">Email</h3>
                </div>
                <p className="text-[12px] text-[#475467]">
                  {booking?.address?.email || "N/A"}
                </p>
              </div>
              <div className="flex flex-col items-center justify-center">
                <div className="flex gap-[6px]">
                  <FaPhoneAlt size={16} color="#E4B703" />
                  <h3 className="text-sm font-bold text-[#475467]">
                    Phone Number
                  </h3>
                </div>
                <p className="text-[12px] text-[#475467]">
                  {booking?.address?.mobileNo || "N/A"}
                </p>
              </div>
              <div className="flex flex-col items-center justify-center">
                <div className="flex gap-[6px]">
                  <MdOutlineDateRange size={18} color="#E4B703" />
                  <h3 className="text-sm font-bold text-[#475467]">Date</h3>
                </div>
                <p className="text-[12px] text-[#475467]">
                  {new Date(booking.createdAt).toLocaleDateString()}
                </p>
              </div>
              <div className="relative inline-block text-left">
                <button
                  type="button"
                  onClick={toggleDropdown}
                  className="inline-flex gap-[6px] items-center justify-center w-full rounded-md border border-yellow-400 shadow-sm px-4 py-2 bg-white text-sm font-medium text-yellow-600  focus:outline-none"
                >
                  {status}
                  {/* <FaChevronDown /> */}
                </button>

                {/* {isOpen && (
                  <div className="absolute flex items-center justify-center mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      {statuses.map((item) => (
                        <button
                          key={item}
                          onClick={() => handleStatusChange(item)}
                          className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                        >
                          {item}
                        </button>
                      ))}
                    </div>
                  </div>
                )} */}
              </div>
            </div>

            <div className="mt-4 border-t-[2px]">
              <div className="mt-4">
                <h3 className="text-sm text-[#475467] font-bold">
                  Device details
                </h3>
                <div className="mt-[8px]">
                  <h3 className="text-[#101828] text-[16px] font-bold">
                    {booking?.modelName || "N/A"}
                  </h3>
                  <p className="text-[12px] text-[#344054]">
                    Type:
                    <span className="font-semibold">
                      {" "}
                      {booking?.categoryName || "N/A"}
                    </span>
                  </p>
                  <p className="text-[12px] text-[#344054]">
                    Brand:
                    <span className="font-semibold">
                      {" "}
                      {booking?.brandName || "N/A"}
                    </span>
                  </p>
                  <p className="text-[12px] text-[#344054]">
                    Series:
                    <span className="font-semibold">
                      {" "}
                      {booking?.modelName || "N/A"}
                    </span>
                  </p>
                  <p className="text-[12px] text-[#344054]">
                    Storage:
                    <span className="font-semibold">
                      {" "}
                      {booking?.storageName || "N/A"}
                    </span>
                  </p>
                  <p className="text-[12px] text-[#344054]">
                    Date of Purchase:
                    <span className="font-semibold">
                      {" "}
                      {booking?.purchaseDuration || "N/A"}
                    </span>
                  </p>
                  <p className="text-[12px] text-[#344054]">
                    Device Value:
                    <span className="font-semibold">
                      {" "}
                      {booking?.deviceValue || "N/A"}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="border rounded-[24px] shadow py-4 px-6 mt-4">
          <div className="grid grid-cols-2 items-center justify-center">
            <div className="col-span-1 ">
              <p className="font-bold text-sm text-[#475467]">Care Plan</p>
              <p className="text-[12px] text-[#101828]  mt-1">
                {" "}
                Care Name:
                <span className="font-semibold">
                  {" "}
                  {booking?.careName || "N/A"}
                </span>
              </p>
              <p className="text-[12px] text-[#101828]">
                {" "}
                Plan Description :
                <span className="font-semibold">
                  {" "}
                  {booking?.planDesc || "N/A"}
                </span>
              </p>
              <p className="text-[12px] text-[#101828]"> Deductibles</p>
            </div>
            {/* <div className="col-span-1 pl-4">
            <p className="font-bold text-sm text-[#475467]">Note</p>
            <p className="text-[12px] text-[#101828]">
              Broken Screen and with many Scraches Broken Screen and with many
              ScrachesBroken Screen and with many Scraches Broken Screen and
              with many Scraches
            </p>
          </div> */}
          </div>
        </div>
        <div className="border rounded-[24px] shadow py-4 px-6 mt-4">
          <div className="grid grid-cols-2 items-center justify-center">
            <div className="col-span-1 ">
              <p className="font-bold text-sm text-[#475467]">Address</p>
              <p className="text-[12px] text-[#101828]  mt-1">
                {booking?.address?.apartment}, {booking?.address?.landMark},{" "}
                {booking?.address?.locality}, {booking?.address?.zipCode}
              </p>
            </div>
          </div>
        </div>
        <div className="border rounded-[24px] shadow py-4 px-6 mt-4">
          <p className="font-bold text-sm text-[#475467]">Payment details</p>
          <div className="flex justify-between">
            <p className="text-[12px]">Total </p>
            <p className="text-[12px] font-semibold">
              {booking?.totalAmount || "N/A"}
            </p>
          </div>
          <div className="flex justify-between">
            <p className="text-[12px]">Discount</p>

            <p className="text-[12px] font-semibold">
              {booking?.amountPaid === null ||
              booking?.amountPaid === 0 ||
              booking?.amountPaid === "N/A"
                ? "N/A"
                : booking?.totalAmount - booking?.amountPaid}
            </p>
          </div>
          {/* <div className="flex justify-between">
          <p className="text-[12px]">Delivery fee</p>
          <p className="text-[12px] font-semibold">1.3 AED</p>
        </div> */}
          <div className="flex justify-between">
            <p className="text-[12px]">Amount paid</p>
            <p className="text-[12px] font-semibold">
              {booking?.amountPaid || "N/A"}
            </p>
          </div>
          <div className="mt-4 border-t-[2px] py-4">
            <div className="flex justify-between">
              <p>Amount to be paid</p>
              <h3 className=" text-[28px]">
                {booking?.amountPaid || "N/A"}
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div className="sm:hidden block">
        <div className="mt-4 bg-white p-2 rounded-md shadow border">
          <div className="flex gap-[14px] px-2">
            {/* <img className="object-contain rounded" src={Profile} alt="" /> */}
            <div>
              <h3 className="text-[#101828] text-[18px] font-semibold">
                {booking?.address?.name || "N/A"}
              </h3>
              <p className="text-[#101828] text-sm">
                ID: {booking?.serviceUuid || "N/A"}
              </p>
            </div>
          </div>
          <div className="flex flex-col items-start mt-[20px] gap-1 px-2">
            <div className="flex flex-col items-center justify-center">
              <div className="flex gap-[6px]">
                <MdOutlineMail size={18} color="#E4B703" />
                <p className="text-[12px] text-[#101828]">
                  {booking?.address?.email || "N/A"}
                </p>
              </div>
            </div>
            <div className="flex flex-col items-center justify-center">
              <div className="flex gap-[6px]">
                <FaPhoneAlt size={16} color="#E4B703" />
                <p className="text-[12px] text-[#101828]">
                  {booking?.address?.mobileNo || "N/A"}
                </p>
              </div>
            </div>
            <div className="flex flex-col items-center justify-center">
              <div className="flex gap-[6px]">
                <MdOutlineDateRange size={18} color="#E4B703" />
                <p className="text-[12px] text-[#101828]">
                  {new Date(booking.createdAt).toLocaleDateString()}
                </p>
              </div>
            </div>
          </div>
          <div className="relative inline-block mt-5 mx-auto w-full px-2">
            <button
              type="button"
              onClick={toggleDropdown}
              className="inline-flex gap-[6px] items-center justify-center w-full rounded-md border border-yellow-400 shadow-sm px-4 py-2 bg-white text-sm font-medium text-yellow-600  focus:outline-none"
            >
              {status}
              {/* <FaChevronDown /> */}
            </button>

            {/* {isOpen && (
              <div className="absolute flex items-center justify-center mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  {statuses.map((item) => (
                    <button
                      key={item}
                      onClick={() => handleStatusChange(item)}
                      className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                    >
                      {item}
                    </button>
                  ))}
                </div>
              </div>
            )} */}
          </div>

          <div className="py-5 border-b-[2px] px-2">
            <div>
              <h3 className="text-sm text-[#475467] font-bold">
                Device details
              </h3>
              <div className="mt-[8px]">
                <h3 className="text-[#101828] text-[16px] font-bold">
                  {booking?.modelName || "N/A"}
                </h3>
                <p className="text-[12px] text-[#344054]">
                  Type:
                  <span className="font-semibold">
                    {" "}
                    {booking?.categoryName || "N/A"}
                  </span>
                </p>
                <p className="text-[12px] text-[#344054]">
                  Brand:
                  <span className="font-semibold">
                    {" "}
                    {booking?.brandName || "N/A"}
                  </span>
                </p>
                <p className="text-[12px] text-[#344054]">
                  Series:
                  <span className="font-semibold">
                    {" "}
                    {booking?.modelName || "N/A"}
                  </span>
                </p>
                <p className="text-[12px] text-[#344054]">
                  Storage:
                  <span className="font-semibold">
                    {" "}
                    {booking?.storageName || "N/A"}
                  </span>
                </p>
                <p className="text-[12px] text-[#344054]">
                  Date of Purchase:
                  <span className="font-semibold">
                    {" "}
                    {booking?.purchaseDuration || "N/A"}
                  </span>
                </p>
                <p className="text-[12px] text-[#344054]">
                  Device Value:
                  <span className="font-semibold">
                    {" "}
                    {booking?.deviceValue || "N/A"}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="py-5 border-b-[2px] px-2">
            <div className="grid grid-cols-2 items-center justify-center">
              <div className="col-span-1">
                <p className="font-bold text-sm text-[#475467]">Description</p>
                <p className="text-[12px] text-[#101828] mt-1">
                  {" "}
                  Care Name:
                  <span className="font-semibold">
                    {" "}
                    {booking?.careName || "N/A"}
                  </span>
                </p>
                <p className="text-[12px] text-[#101828]">
                  {" "}
                  Plan Description :
                  <span className="font-semibold">
                    {" "}
                    {booking?.planDesc || "N/A"}
                  </span>
                  
                </p>
                <p className="text-[12px] text-[#101828]"> Deductibles</p>
              </div>
            </div>
          </div>

          <div className="py-5 border-b-[2px] px-2">
            <div className="grid grid-cols-2 items-center justify-center">
              <div className="col-span-1">
                <p className="font-bold text-sm text-[#475467]">Address</p>
                <p className="text-[12px] text-[#101828]  mt-1">
                  {booking?.address?.apartment}, {booking?.address?.landMark},{" "}
                  {booking?.address?.locality}, {booking?.address?.zipCode}
                </p>
              </div>
            </div>
          </div>

          <div className="px-2 py-5">
            <p className="font-bold text-sm text-[#475467]">Payment details</p>
            <div className="flex justify-between">
              <p className="text-[12px]">Total </p>
              <p className="text-[12px] font-semibold">
                {booking?.totalAmount || "N/A"}
              </p>
            </div>
            <div className="flex justify-between">
              <p className="text-[12px]">Discount</p>
              <p className="text-[12px] font-semibold ">
                {booking?.amountPaid === null ||
                booking?.amountPaid === 0 ||
                booking?.amountPaid === "N/A"
                  ? "N/A"
                  : booking?.totalAmount - booking?.amountPaid}
              </p>
            </div>
            <div className="flex justify-between">
              <p className="text-[12px]">Amount paid</p>
              <p className="text-[12px] font-semibold">
                {booking?.amountPaid || "N/A"}
              </p>
            </div>
            <div className="mt-4 border-t-[2px] py-4">
              <div className="flex justify-between">
                <p>Amount to be paid</p>
                <h3 className=" text-[18px]">
                  {booking?.amountPaid || "N/A"}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CareBookingDetails;
