import React, { useState, useEffect, useCallback } from "react";
import {
  Table,
  Select,
  Dropdown,
  Menu,
  Spin,
  Button,
  DatePicker,
  Pagination,
} from "antd";
import { FaSearch } from "react-icons/fa";
import { IoOptionsOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroll-component";
import { GiReturnArrow } from "react-icons/gi";
import dayjs from "dayjs";
import { MdKeyboardArrowRight } from "react-icons/md";

const { Option } = Select;
const { RangePicker } = DatePicker;

function CareBookings() {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [brands, setBrands] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedDateRange, setSelectedDateRange] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);

  //get page from query params
  const page = new URLSearchParams(window.location.search).get("page");

  const navigate = useNavigate();

  const toggleFilter = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const handleRowClick = (record) => {
    navigate(`/admin/care/booking-details`, {
      state: { booking: record.fullItem },
    });
  };

  const fetchData = async (pageNo = 0) => {
    setLoading(true);

    const baseUrl = `${process.env.REACT_APP_BASE_URL}:8899/booking-service/admin/filtered-care-bookings`;

    // Constructing the params dynamically
    let params = {
      pageNo: pageNo.toString(),
      pageSize: "15",
      sortBy: "createdAt",
      direction: "ASC",
    };

    if (selectedDateRange && selectedDateRange.length === 2) {
      // Ensure date formatting is correct
      const start = dayjs(selectedDateRange[0])
        .startOf("day")
        .format("YYYY-MM-DDTHH:mm:ss");
      const end = dayjs(selectedDateRange[1])
        .endOf("day")
        .format("YYYY-MM-DDTHH:mm:ss");
      console.log("Start:", start, "End:", end);
      params.startTime = start;
      params.endTime = end;
    }

    if (selectedBrand) {
      params.brandSku = selectedBrand;
    }

    if (selectedCategory) {
      params.categorySku = selectedCategory;
    }

    if (selectedStatus) {
      params.serviceStatus = selectedStatus;
    }

    const options = {
      method: "GET",
      url: baseUrl,
      params,
    };

    try {
      const { data } = await axios.request(options);
      console.log("Fetched data:", data);
      const formattedData = data.data.content.map((item, index) => ({
        key: index + 1 + pageNo * 15, // Adjust key based on page
        sno: index + 1 + pageNo * 15, // Adjust serial number based on page
        name: item?.address?.name,
        bookingId: item?.serviceUuid,
        date: new Date(item.createdAt).toLocaleDateString(),
        brand: item?.brandName,
        category: item?.categoryName,
        status: item?.careStatus, // No need to check for care status
        amount: item?.totalAmount,
        fullItem: item, // Store the entire item object
      }));
      setDataSource(formattedData);
      setCurrentPage(data.data.currentPage);
      setTotalPages(data.data.totalPages);
    } catch (error) {
      console.error("Failed to fetch data:", error);
    } finally {
      setLoading(false);
    }
  };
  const handlePageChange = (page) => {
    setCurrentPage(page - 1); // Adjust because Ant Design Pagination is 1-based
    // fetchData(page - 1);
    navigate(`/admin/care-bookings?page=${page}`);
  };

  const fetchBrands = useCallback(async () => {
    const options = {
      method: "GET",
      url: `${process.env.REACT_APP_BASE_URL}:8070/master-service/brands/find-by-category-for-repair?categorySku=${selectedCategory}`,
      headers: { accept: "*/*" },
    };

    try {
      const { data } = await axios.request(options);
      setBrands(data);
    } catch (error) {
      console.error("Failed to fetch brands:", error);
    }
  }, [selectedCategory]);

  const fetchCategories = async () => {
    const options = {
      method: "GET",
      url: `${process.env.REACT_APP_BASE_URL}:8070/master-service/categories`,
      headers: { accept: "*/*" },
    };

    try {
      const { data } = await axios.request(options);
      setCategories(data.primaryEntitys);
    } catch (error) {
      console.error("Failed to fetch categories:", error);
    }
  };

  useEffect(() => {
    fetchData((page || 1) - 1);
    fetchCategories();
  }, [selectedDateRange, selectedBrand, selectedCategory, selectedStatus]);

  useEffect(() => {
    if (page) {
      setCurrentPage((page || 1) - 1);
      fetchData((page || 1) - 1);
    }
  }, [page]);

  useEffect(() => {
    fetchBrands();
  }, [fetchBrands]);

  const handleDateChange = (dates) => {
    setSelectedDateRange(dates);
  };

  const handleBrandChange = (value) => {
    setSelectedBrand(value);
  };

  const handleCategoryChange = (value) => {
    setSelectedCategory(value);
  };

  const handleStatusChange = (value) => {
    setSelectedStatus(value);
  };

  const clearDateFilter = () => {
    setSelectedDateRange([]);
  };

  const clearBrandFilter = () => {
    setSelectedBrand(null);
  };

  const clearCategoryFilter = () => {
    setSelectedCategory(null);
  };

  const clearStatusFilter = () => {
    setSelectedStatus(null);
  };

  const columns = [
    {
      title: "S/NO",
      dataIndex: "sno",
      key: "sno",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Booking ID",
      dataIndex: "bookingId",
      key: "bookingId",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "",
      key: "arrow",
      render: () => <MdKeyboardArrowRight size={20} />,
    },
  ];
  useEffect(() => {
    console.log("Updated Date Range:", selectedDateRange);
  }, [selectedDateRange]);

  const filterMenu = (
    <Menu>
      <Menu.Item key="date">
        <RangePicker
          value={selectedDateRange}
          onChange={handleDateChange}
          format="YYYY-MM-DD"
        />
        {selectedDateRange.length === 2 && (
          <Button
            type="link"
            onClick={clearDateFilter}
            style={{ display: "block", marginTop: "8px" }}
          >
            Clear Date Filter
          </Button>
        )}
      </Menu.Item>
      <Menu.Item key="category">
        <InfiniteScroll
          dataLength={categories.length}
          next={() => {}}
          hasMore={false}
        >
          <Select
            showSearch
            placeholder="Select a category"
            optionFilterProp="children"
            onChange={handleCategoryChange}
            style={{ width: "100%" }}
            value={selectedCategory}
          >
            {categories.map((category) => (
              <Option key={category.skuCode} value={category.skuCode}>
                {category.name}
              </Option>
            ))}
          </Select>
        </InfiniteScroll>
        {selectedCategory && (
          <Button
            type="link"
            onClick={clearCategoryFilter}
            style={{ display: "block", marginTop: "8px" }}
          >
            Clear Category Filter
          </Button>
        )}
      </Menu.Item>
      <Menu.Item key="brand">
        <InfiniteScroll
          dataLength={brands.length}
          next={() => {}}
          hasMore={false}
        >
          <Select
            showSearch
            placeholder="Select a brand"
            optionFilterProp="children"
            onChange={handleBrandChange}
            style={{ width: "100%" }}
            value={selectedBrand}
          >
            {brands.map((brand) => (
              <Option key={brand.skuCode} value={brand.skuCode}>
                {brand.name}
              </Option>
            ))}
          </Select>
        </InfiniteScroll>
        {selectedBrand && (
          <Button
            type="link"
            onClick={clearBrandFilter}
            style={{ display: "block", marginTop: "8px" }}
          >
            Clear Brand Filter
          </Button>
        )}
      </Menu.Item>

      <Menu.Item key="status">
        <Select
          showSearch
          placeholder="Select a status"
          optionFilterProp="children"
          onChange={handleStatusChange}
          style={{ width: "100%" }}
          value={selectedStatus}
        >
          <Option value="ADDRESS_PENDING">ADDRESS_PENDING</Option>
          <Option value="PAYMENT_PENDING">PAYMENT_PENDING</Option>
          <Option value="PAYMENT_SUCCESSFUL">PAYMENT_SUCCESSFUL</Option>
        </Select>
        {selectedStatus && (
          <Button
            type="link"
            onClick={clearStatusFilter}
            style={{ display: "block", marginTop: "8px" }}
          >
            Clear Status Filter
          </Button>
        )}
      </Menu.Item>
    </Menu>
  );

  return (
    <div>
      <div className="sm:flex flex-col hidden">
        <div className="flex justify-between items-center">
          <h3 className="text-[#475467]">Comprehensive List</h3>
          <div className="relative flex items-center">
            {/* <div className="relative w-full ml-4">
            <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            <input
              type="text"
              className="w-full pl-10 p-2 border rounded-md focus:outline-none"
              placeholder="Search"
            />
          </div> */}
            <Dropdown
              overlay={filterMenu}
              trigger={["click"]}
              open={isFilterOpen}
            >
              <button
                className="flex items-center justify-center px-4 py-[9px] rounded-md shadow-sm border focus:outline-none ml-4"
                onClick={toggleFilter}
              >
                <IoOptionsOutline />
              </button>
            </Dropdown>
          </div>
        </div>
        {loading ? (
          <div className="flex justify-center items-center h-[calc(100vh-30px)]">
            <Spin size="large" />
          </div>
        ) : (
          <div className="scroll-smooth grow">
            <Table
              className="mt-4"
              dataSource={dataSource}
              columns={columns}
              loading={loading}
              pagination={false} // Disable default pagination
              onRow={(record) => ({
                onClick: () => handleRowClick(record),
              })}
            />
            <Pagination
              style={{ position: "absolute", right: 0, padding: "16px 0" }}
              current={currentPage + 1}
              total={totalPages * 15}
              pageSize={15}
              onChange={handlePageChange}
              showSizeChanger={false} // Hide the "page size" selector
            />
          </div>
        )}
      </div>
      <div className="sm:hidden block">
        <div className="text-xl md:text-2xl font-bold my-2 ml-4 md:ml-8 lg:ml-16">
          Care Bookings
        </div>
        <div className="flex items-center mt-4 bg-white p-2 rounded-md shadow border">
          <FaSearch className="text-gray-500 mr-2" />
          <input
            type="text"
            placeholder="Search"
            className="w-full border-none outline-none"
          />
          <Dropdown
            overlay={filterMenu}
            trigger={["click"]}
            open={isFilterOpen}
          >
            <button
              className="flex items-center justify-center px-4 py-[9px] rounded-md shadow-sm border focus:outline-none ml-4"
              onClick={toggleFilter}
            >
              <IoOptionsOutline />
            </button>
          </Dropdown>
        </div>

        {loading ? (
          <div className="flex justify-center items-center h-[calc(100vh-30px)]">
            <Spin size="large" />
          </div>
        ) : (
          dataSource.map((item) => (
            <div
              key={item.key}
              onClick={() => handleRowClick(item)}
              className="bg-white p-4 rounded-md shadow mt-4 border cursor-pointer"
            >
              <div>
                <h3 className="text-[#101828] text-[18px] font-bold">
                  {item.name}
                </h3>
              </div>
              <div className="flex justify-between items-center border-b-2 pb-2">
                <p className="text-[#475467] font-medium">{item.bookingId}</p>
                <p className="text-[#475467] font-medium">{item.date}</p>
              </div>
              <div className="flex justify-between items-center py-2">
                <p className="text-[#101828] text-[18px] font-semibold">
                  {item.brand}
                </p>
                <p className="text-[#101828] text-[18px] font-semibold">
                  {item.amount} AED
                </p>
              </div>
              <div className="flex justify-between items-center">
                <p className="text-[14px] text-[#475467]">
                  {item.fullItem.description || "No description available"}
                </p>
                <span
                  className={`bg-[#FBC903] font-semibold text-sm text-[#101828] px-4 py-1 rounded-[6px] ${
                    item.status === "PENDING" ? "bg-yellow-500" : ""
                  }`}
                >
                  {item.status}
                </span>
              </div>
            </div>
          ))
        )}
        <Pagination
          style={{ textAlign: "center", marginTop: "16px" }}
          current={currentPage + 1}
          total={totalPages * 15}
          pageSize={15}
          onChange={handlePageChange}
          showSizeChanger={false}
        />
      </div>
    </div>
  );
}

export default CareBookings;
